<template>
  <section>
    <div class="page">
      <span class="title"> My New Hires </span>
      <!-- vfor user here -->
      <div v-for="user in trainees.reports" :key="user.id" class="user">
        <div class="user-dash">
          <div class="name-container">
            <AvatarCircle
              class="avatar"
              size="50"
              :avatar="avatar"
              should-show-third
            />
            <div class="user-name">
              {{ user.first_name }} {{ user.last_name }}
            </div>
          </div>
          <div v-if="user.assistance" class="icon-container">
            <div class="assistance">!</div>
            Needs Assistance
          </div>
          <div class="icon-container">
            <div class="ready">{{ user.readyCount }}</div>
            Ready for Review
          </div>
          <img
            class="expand"
            v-if="!user.dropdown"
            :src="expand"
            @click="user.dropdown = true"
            alt=""
          />
          <img
            class="expand"
            v-if="user.dropdown"
            :src="collapse"
            @click="user.dropdown = false"
            alt=""
          />
        </div>

        <div v-if="user.dropdown" class="dropdown">
          <div class="hire-details">
            <div class="skill">
              <span class="bold">Skill: {{ user.role }}</span>
            </div>
            <div class="skill">
              <span class="bold">Hire Date: </span
              >{{ user.last_hire_date.split("T")[0] }}
            </div>
            <div class="skill">
              <span class="bold">Email: {{ user.work_email_address }}</span>
              <span class="email"></span>
            </div>
          </div>
          <div v-for="task in user.sections" :key="task" class="task-block">
            <div class="task-title">{{ task?.name }}</div>
            <div v-for="subtask in task.tasks" :key="subtask" class="subtask">
              <div class="subtask-title">
                {{ subtask?.name }}
              </div>
              <div class="subtask-status-block">
                <img class="status-icon" :src="getIcon(subtask)" alt="" />
                <div class="status-name">
                  {{ getStatus(subtask) }}
                </div>
              </div>
              <div
                class="view-button"
                @click="openTask(user.id, subtask?.name)"
              >
                View
              </div>
            </div>
          </div>
        </div>
        <!-- to here -->
      </div>
    </div>
  </section>
</template>

<script setup>
import CircularProgress from "@/components/CircularProgress";
import StatusKeyHelper from "@/components/StatusKeyHelper";
import splitTasksArrayByHalfs from "@/utils/splitTasksArrayByHalfs";
import HRItem from "@/components/HRItem";
import Help from "@/components/Help";
import AssistanceButton from "@/components/AssistanceButton";
import { onMounted, ref, defineEmits } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import ChecklistAPI from "@/api/ChecklistAPI";
import UserAPI from "@/api/UserAPI";
import { useStore } from "vuex";
import AvatarCircle from "@/components/AvatarCircle";
import expand from "../assets/icons/expand.png";
import collapse from "../assets/icons/collapse.png";
import attentionRequired from "@/assets/icons/attention-required-status.svg";
import completed from "@/assets/icons/completed-status.svg";
import inReview from "@/assets/icons/in-review-status.svg";
import available from "@/assets/icons/available-status.svg";

const emit = defineEmits({
  tabClick: (path) => typeof path === "string",
  signOut: () => true,
});

const open = ref(false);
const store = useStore();
const avatar = ref();
const trainees = ref([]);
onMounted(async () => {
  avatar.value = await store.state.avatar;
  trainees.value = await UserAPI.getMyTrainees();

  trainees.value.reports.forEach(async (user) => {
    if (!user.role) {
      if (user.job_profile_id === "HDISIC") user.role = "IC";
      if (user.job_profile_id === "SHDISD") user.role = "DIM";
      if (user.job_profile_id === "SBHDEI") user.role = "BIM";
      if (user.job_profile_id === "HDISAM") user.role = "AIS";
    }
    const tasks = await ChecklistAPI.getTrainee(user.id, user.role);

    const items = [];
    tasks.forEach((item) => {
      const included = items.find((task) => task.name === item.name);
      if (!included) items.push(item);
      else {
        item?.tasks.forEach((task) => {
          included?.tasks.push(task);
        });
      }
      const thisTask = items.find((task) => task.name === item.name);
      thisTask?.tasks?.sort(function (a, b) {
        return a.order - b.order;
      });
    });
    items.forEach((item) => {
      const subs = [];
      item.tasks.forEach((task) => {
        const included = subs?.find((sub) => sub.name === task.name);
        if (!included) {
          subs.push(task);
        } else {
          task.subtasks?.forEach((sub) => {
            included.subtasks?.push(sub);
          });
          task?.progresses?.forEach((sub) => {
            included.progresses?.push(sub);
          });
        }
      });
      console.log("sub:", subs);
      item.tasks = subs;
      console.log(items);
    });
    const sections = [];
    items.forEach((item) => {
      item.needsAttention = false;
      item.in_review = true;
      item.completed = true;
      item.tasks?.forEach((task) => {
        if (!task.progresses) {
          item.in_review = false;
          item.completed = false;
        }
        task.progresses?.forEach((prog) => {
          if (prog.status === "attention-required") {
            item.needsAttention = true;
            user.assistance = true;
          }
          if (prog.status !== "in-review") item.in_review = false;
          if (prog.status !== "completed") item.completed = false;
        });
      });
      const included = sections.find(
        (section) => section?.name === item.section
      );

      if (!included) {
        const thisItem = {
          name: item.section,
          tasks: [item],
        };
        sections.push(thisItem);
      } else {
        // included.tasks.push(item);
        // item?.tasks.forEach((task) => {
        const inc = included.tasks.find((it) => it.name === item.name);
        if (!inc) {
          included?.tasks.push(item);
        } else {
          item?.subtasks?.forEach((sub) => {
            inc.subtasks.push(sub);
          });
          item?.progesses?.forEach((sub) => {
            inc.progesses.push(sub);
          });
        }
        // });
      }
    });
    user.first_name = user.preferred_name
      ? user.preferred_name
      : user.employee_first_name;
    user.last_name = user.preferred_last_name
      ? user.preferred_last_name
      : user.employee_last_name;
    if (!user.role) {
    }
    user.sections = sections;
    user.tasks = tasks;
    user.dropdown = false;
    user.readyCount = 0;
    user.assistance = false;
    user.tasks.forEach((task) => {
      if (task.needsAttention) user.assistance = true;
      if (task.in_review) user.readyCount++;
    });
  });
  //  const check = ChecklistAPI.getTrainee(trainees.value.reports[0].id)
  // console.log('checklist:', check)
});
const getIcon = (task) => {
  if (task.needsAttention) return attentionRequired;
  else if (task.in_review) return inReview;
  else if (task.completed) return completed;
  else return available;
};
const getStatus = (task) => {
  if (task.needsAttention) return "Needs Assistance";
  else if (task.in_review) return "Ready for Review";
  else if (task.completed) return "Completed";
  else return "To-Do";
};

const openTask = (id, task) => {
  emit("tabClick", `/new-hires-task/${id}?task=${task}`, "");
};
</script>

<style scoped>
.avatar {
  margin-right: 10px;
}
.hire-details {
  border-radius: 10px;
  border: 1px solid var(--light-grey, #e4e4e4);
  background: rgba(244, 246, 245, 0.71);
  display: flex;
  width: 875px;
  padding: 10px 20px;
  align-items: center;
  gap: 50px;
  margin-bottom: 30px;
}
.title {
  color: #ff8217;
  font-size: 18px;
  font-family: Helvetica Neue LT Std;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.icon-container {
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 177px;
  color: #5f6562;
  font-size: 14px;
  font-family: Helvetica Neue LT Std;
  font-style: normal;
  font-weight: 700;
  line-height: 381%;
}
.name-container {
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.expand {
  margin-left: auto;
}
.user-name {
  color: #5f6562;

  font-size: 24px;
  font-family: Helvetica Neue LT Std;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  width: 300px;
}
.user {
  display: inline-flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  border-radius: 20px;
  background: #fff;
  width: 915px;
  margin-top: 30px;
}
.assistance {
  width: 41px;
  height: 41px;
  border-radius: 30px;
  background: var(--alert, #b32d22);
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Helvetica Neue LT Std;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.ready {
  width: 41px;
  height: 41px;
  border-radius: 30px;
  background: var(--darker-orange, #e87524);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Helvetica Neue LT Std;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 10px;
}
.page {
  display: flex;
  flex-direction: column;
}
.user-dash {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 875px;
}
.task-title {
  border-bottom: 1px solid var(--light-grey, #e4e4e4);
  background: var(--system-light-grey, #f4f6f5);
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #e87524;
  font-size: 24px;
  font-family: Helvetica Neue LT Std;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.task-block {
  border-radius: 10px;
  border: 1px solid var(--light-grey, #e4e4e4);
  display: flex;
  width: 875px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.view-button {
  border-radius: 5px;
  border: 1px solid var(--medium-grey, #908f8f);
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  cursor: pointer;
}
.subtask {
  background: rgba(244, 246, 245, 0.46);
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 75px;
  align-self: stretch;
  border-bottom: 1px solid var(--light-grey, #e4e4e4);
}
.subtask-title {
  color: var(--dark-grey, #5f6562);
  font-size: 18px;
  font-family: Helvetica Neue LT Std;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 48%;
}
.subtask-status-block {
  display: flex;
  /* margin-left: 40%; */
  /* justify-content: center; */
  align-items: center;
}
.status-icon {
  margin-right: 5px;
}
</style>
